import * as React from "react";

import PropTypes from "prop-types";

import LogoutIcon from "@mui/icons-material/Logout";
import { Grid, AppBar, Toolbar, Typography, IconButton } from "@mui/material";

const Header = ({ userName }) => {
  return (
    <Grid component="header" className="Header">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h4" sx={{ fontWeight: 500, flexGrow: 1 }}>
            Opportunity Hub
          </Typography>
          {userName && (
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                backgroundColor: "secondary.main",
                px: 2,
                py: 0.5,
                borderRadius: 5,
                mr: 2,
              }}
            >
              {userName}
            </Typography>
          )}
          {userName && (
            <IconButton
              variant="contained"
              aria-label="logout"
              href={"/api/auth/logout/" + userName}
              sx={{
                backgroundColor: "secondary.main",
                color: "white",
              }}
            >
              <LogoutIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </Grid>
  );
};

Header.props = {
  userName: PropTypes.string,
};

export default Header;
