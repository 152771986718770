import React from "react";

import parse from "html-react-parser";
import PropTypes from "prop-types";

import { Button, Chip, Grid, Typography } from "@mui/material";

const CurrentOpportunity = ({
  currentOpportunity,
  jobTypes,
  citizenshipTypes,
}) => {
  const applicationDeadline = new Date(
    currentOpportunity ? currentOpportunity.opportunityApplicationDeadline : ""
  );
  const dateAdded = new Date(
    currentOpportunity ? currentOpportunity.opportunityDateAdded : ""
  );

  return (
    currentOpportunity && (
      <Grid
        container
        sx={{ flexDirection: "column", mb: 2 }}
        className="Home-currentOpportunityContainer"
      >
        <Typography
          variant="body1"
          sx={{
            color: "primary.main",
            fontWeight: 500,
            fontSize: "24px",
            overflow: "hidden",
          }}
          className="Home-currentOpportunityTitle"
        >
          {currentOpportunity.opportunityTitle}
        </Typography>
        <Grid container sx={{ mb: 2 }}>
          <Grid container sx={{ flexDirection: "column", width: "50%" }}>
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontSize: "20px",
                flexGrow: 1,
              }}
              className="Home-currentOpportunityCompany"
            >
              {currentOpportunity.opportunityCompany}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "tertiary.main",
                fontWeight: 500,
                fontSize: "16px",
                flexGrow: 1,
                mb: 2,
              }}
              className="Home-currentOpportunityLocation"
            >
              {currentOpportunity.opportunityLocation !== "None, None"
                ? currentOpportunity.opportunityLocation
                : "N/A"}
            </Typography>
            <Grid
              container
              sx={{ mb: 1, gap: 0.25 }}
              className="Home-currentOpportunityCitizenshipAndJobType"
            >
              <Chip
                label={
                  citizenshipTypes.find(
                    (citizenshipType) =>
                      citizenshipType.id ===
                      currentOpportunity.opportunityCitizenship
                  ).label
                }
                size="small"
                color="primary"
                className="Home-currentOpportunityCitizenship"
              />
              <Chip
                label={
                  jobTypes.find(
                    (jobType) =>
                      jobType.id === currentOpportunity.opportunityType
                  ).label
                }
                size="small"
                color="primary"
                className="Home-currentOpportunityType"
              />
            </Grid>
            <Button
              sx={{ borderRadius: 5, mr: "auto" }}
              variant="contained"
              color="primary"
              href={currentOpportunity.opportunityApplicationLink}
              target="_blank"
            >
              <Typography
                variant="body1"
                sx={{
                  color: "white",
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                Apply
              </Typography>
            </Button>
          </Grid>
          <Grid container sx={{ flexDirection: "column", width: "50%" }}>
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Application Deadline
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "tertiary.main",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              {applicationDeadline.toLocaleDateString()}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              Date Added
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "tertiary.main",
                fontWeight: 500,
                fontSize: "20px",
              }}
            >
              {dateAdded.toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{ mb: 1 }}>
          <Typography
            variant="body1"
            sx={{
              color: "primary.main",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Skills
          </Typography>
          <Grid
            container
            sx={{ gap: 0.25 }}
            className="Home-currentOpportunitySkills"
          >
            {currentOpportunity.opportunitySkills &&
              currentOpportunity.opportunitySkills
                .split(";")
                .map((skill) => (
                  <Chip
                    key={Math.random() * 10000}
                    label={skill}
                    size="small"
                    color="secondary"
                    className="Home-currentOpportunitySkills"
                  />
                ))}
          </Grid>
        </Grid>
        <Grid>
          <Typography
            variant="body1"
            sx={{
              color: "primary.main",
              fontWeight: 500,
              fontSize: "24px",
            }}
          >
            Description
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: "tertiary.main",
              fontWeight: 500,
              fontSize: "16px",
              flexGrow: 1,
              whiteSpace: "pre-wrap"
            }}
            className="Home-currentOpportunityDescription"
          >
            {parse(currentOpportunity.opportunityDescription)}
          </Typography>
        </Grid>
      </Grid>
    )
  );
};

CurrentOpportunity.props = {
  currentOpportunity: PropTypes.object,
  jobTypes: PropTypes.array,
  citizenshipTypes: PropTypes.array,
};

export default CurrentOpportunity;
