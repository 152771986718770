import React, { useState, useEffect } from "react";

import Main from "./Main";
import Login from "./Login";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import UsersService from "../api/services/UsersService";

const Home = () => {
  const [user, setUser] = useState({
    userName: "",
    userAffiliation: "",
    userSupressDisplay: "",
  });

  useEffect(() => {
    async function fetchData() {
      await UsersService.getCurrentUser().then((response) => {
        const data = response.data;
        setUser(data);
      });
    }
    // Comment this out and put a username into the useState at the top for
    // local development.
    fetchData();
  }, [setUser]);

  return (
    <>
      <Header userName={user.userName} />
      {user.userName && user.userAffiliation !== "" ? (
        <Main userAffiliation={user.userAffiliation} />
      ) : (
        <Login />
      )}
      <Footer />
    </>
  );
};

export default Home;
