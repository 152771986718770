import React, { useState, useEffect } from "react";

import * as dayjs from "dayjs";
import PropTypes from "prop-types";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DesignServicesOutlinedIcon from "@mui/icons-material/DesignServicesOutlined";
import { Grid, Button, Dialog, MenuItem, TextField, IconButton, DialogTitle, Autocomplete, DialogActions, DialogContent, DialogContentText } from "@mui/material";

import EnhancedTextField from "./EnhancedTextField";
import SkillsService from "../api/services/SkillsService";
import LocationAutocomplete from "./LocationAutocomplete";
import OpportunitiesService from "../api/services/OpportunitiesService";
const EditOpportunity = ({
  opportunityID,
  jobTypes,
  citizenshipTypes,
  updateTable,
  setUpdateTable,
}) => {
  const [open, setOpen] = React.useState(false);

  const [opportunityTitle, setOpportunityTitle] = React.useState("");
  const [opportunityTitleError, setOpportunityTitleError] =
    React.useState(false);

  const [opportunityCompany, setOpportunityCompany] = React.useState("");
  const [opportunityCompanyError, setOpportunityCompanyError] =
    React.useState(false);

  const [opportunityApplicationLink, setOpportunityApplicationLink] =
    React.useState("");
  const [opportunityApplicationLinkError, setOpportunityApplicationLinkError] =
    React.useState(false);

  const [opportunityDescription, setOpportunityDescription] =
    React.useState("");
  const [opportunityDescriptionError, setOpportunityDescriptionError] =
    React.useState(false);

  const [opportunityCitizenshipType, setOpportunityCitizenshipType] =
    React.useState(citizenshipTypes[0].id);
  const [opportunityCitizenshipTypeError, setOpportunityCitizenshipTypeError] =
    React.useState(false);

  const [opportunityJobType, setOpportunityJobType] = React.useState(
    jobTypes[0].id
  );
  const [opportunityJobTypeError, setOpportunityJobTypeError] =
    React.useState(false);

  const [opportunityLocation, setOpportunityLocation] = React.useState("");
  const [opportunityLocationError, setOpportunityLocationError] =
    React.useState(false);

  const [opportunityCity, setOpportunityCity] = React.useState("");
  const [opportunityState, setOpportunityState] = React.useState("");

  const [opportunityApplicationDeadline, setOpportunityApplicationDeadline] =
    React.useState(dayjs().add(14, "day"));
  const [selectedOpportunitySkills, setSelectedOpportunitySkills] =
    React.useState([]);
  const [selectedOpportunitySkillsError, setSelectedOpportunitySkillsError] =
    React.useState(false);

  const [skills, setSkills] = useState([]);

  useEffect(() => {
    async function fetchData() {
      await SkillsService.getSkills().then((response) => {
        const data = response.data;
        setSkills(data);
      });
    }
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);

    OpportunitiesService.getOpportunityById(opportunityID).then((response) => {
      const opportunity = response.data;
      console.log(opportunity);
      setOpportunityTitle(opportunity.opportunityTitle);
      setOpportunityCompany(opportunity.opportunityCompany);
      setOpportunityApplicationLink(opportunity.opportunityApplicationLink);
      setOpportunityDescription(opportunity.opportunityDescription);
      setOpportunityCitizenshipType(opportunity.opportunityCitizenship);
      setOpportunityJobType(opportunity.opportunityType);
      setOpportunityLocation(opportunity.opportunityLocation);

      const cityState = opportunity.opportunityLocation.split(", ");

      setOpportunityCity(cityState[0]);
      setOpportunityState(cityState[1]);

      const skillNames = opportunity.opportunitySkills.split(";");

      if (skillNames[0] !== "") {
        const opportunitySkills = [];

        for (let skillIndex = 0; skillIndex < skillNames.length; skillIndex++) {
          opportunitySkills.push({
            skillId: skillIndex,
            skillName: skillNames[skillIndex],
          });
        }

        console.log(opportunitySkills);

        setSelectedOpportunitySkills(opportunitySkills);
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
    resetHooks();
  };

  const resetHooks = () => {
    setOpportunityTitle("");
    setOpportunityTitleError(false);
    setOpportunityCompany("");
    setOpportunityCompanyError(false);
    setOpportunityApplicationLink("");
    setOpportunityApplicationLinkError(false);
    setOpportunityDescription("");
    setOpportunityDescriptionError(false);
    setOpportunityCitizenshipType(citizenshipTypes[0].id);
    setOpportunityCitizenshipTypeError(false);
    setOpportunityJobType(jobTypes[0].id);
    setOpportunityJobTypeError(false);
    setOpportunityLocation("");
    setOpportunityLocationError(false);
    setOpportunityCity("");
    setOpportunityState("");
    setOpportunityApplicationDeadline(dayjs().add(14, "day"));
    setSelectedOpportunitySkills([]);
    setSelectedOpportunitySkillsError(false);
  };

  const isWeekend = (date) => {
    const day = date.day();

    return day === 0 || day === 6;
  };

  const handleSkillSearch = (skillNameQuery) => {
    async function fetchData() {
      try {
        await SkillsService.getSkillByNameQuery(skillNameQuery).then(
          (response) => {
            const data = response.data;
            setSkills(data);
          }
        );
      } catch (error) {
        setSkills([]);
      }
    }
    if (skillNameQuery) {
      fetchData();
    }
  };

  const handleSkillsBlur = () => {
    if (selectedOpportunitySkills.length > 0) {
      setSelectedOpportunitySkillsError(false);
    } else {
      setSelectedOpportunitySkillsError(true);
    }
  };

  const handleSubmit = () => {
    if (opportunityTitle.trim().length === 0 || opportunityTitleError) {
      alert("Please fix the title for this opportunity.");
    } else if (
      opportunityCompany.trim().length === 0 ||
      opportunityCompanyError
    ) {
      alert("Please fix the company for this opportunity.");
    } else if (
      opportunityApplicationLink.trim().length === 0 ||
      opportunityApplicationLinkError
    ) {
      alert("Please fix the application link for this opportunity.");
    } else if (
      opportunityDescription.trim().length === 0 ||
      opportunityDescriptionError
    ) {
      alert("Please fix the description for this opportunity.");
    } else if (
      opportunityLocation.trim().length === 0 ||
      opportunityCity.trim().length === 0 ||
      opportunityState.trim().length === 0 ||
      opportunityLocationError
    ) {
      alert("Please fix the location for this opportunity.");
    } else if (opportunityCitizenshipTypeError) {
      alert("Please fix the citizenship type for this opportunity.");
    } else if (opportunityJobTypeError) {
      alert("Please fix the job type for this opportunity.");
    } else if (
      selectedOpportunitySkills.length === 0 ||
      selectedOpportunitySkillsError
    ) {
      alert("Please fix the selected skills for this opportunity.");
    } else {

      OpportunitiesService.updateOpportunityById(opportunityID, {
        opportunityTitle: opportunityTitle,
        opportunityCompany: opportunityCompany,
        opportunityLocation: opportunityCity + ", " + opportunityState,
        opportunityApplicationDeadline: opportunityApplicationDeadline.$d,
        opportunityApplicationLink: opportunityApplicationLink,
        opportunityCitizenship: opportunityCitizenshipType,
        opportunityType: opportunityJobType,
        opportunityDateAdded: dayjs().$d,
        opportunityDescription: opportunityDescription,
        opportunitySkills: selectedOpportunitySkills,
      }).then((response) => {
        console.log(response);
        setUpdateTable(!updateTable);
      });

      setOpen(false);
      resetHooks();
    }
  };

  return (
    <Grid>
      <IconButton onClick={handleClickOpen} sx={{ p: 0 }}>
        <DesignServicesOutlinedIcon fontSize="large" color="quaternary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle
          color="primary"
          sx={{ fontSize: 24, fontWeight: 600, mx: "auto" }}
        >
          Edit This New Opportunity
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            sx={{ p: 2, width: "50%" }}
          >
            <Grid item xs={6}>
              <Grid item>
                <DialogContentText>Title *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 55,
                    },
                  }}
                  placeholder="Research Intern, Chemistry"
                  label="Title"
                  regexPattern="(?=.*\w).+"
                  defaultValue=""
                  value={opportunityTitle}
                  setFunction={setOpportunityTitle}
                  errorMessage="This title is invalid, please enter a different title."
                  required={true}
                  multiline={false}
                  select={false}
                  maxLength={100}
                  error={opportunityTitleError}
                  setError={setOpportunityTitleError}
                  children={{}}
                />
              </Grid>
              <Grid item>
                <DialogContentText>Company *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 55,
                    },
                  }}
                  placeholder="Zymo Research"
                  label="Company"
                  regexPattern="(?=.*\w).+"
                  defaultValue=""
                  value={opportunityCompany}
                  setFunction={setOpportunityCompany}
                  errorMessage="This company is invalid, please enter a different company."
                  required={true}
                  multiline={false}
                  select={false}
                  maxLength={100}
                  error={opportunityCompanyError}
                  setError={setOpportunityCompanyError}
                  children={{}}
                />
              </Grid>
              <Grid item>
                <DialogContentText>Application Link *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 55,
                    },
                  }}
                  placeholder="https://www.yourlink.com"
                  label="Application Link"
                  regexPattern="(https:\/\/)?(www\.)?((?=[a-zA-Z])[a-zA-Z0-9_-]+(?<=[a-zA-Z])\.)+[a-zA-Z]{2,}\/?(\/?[a-zA-Z0-9-#?_]*)*|[a-zA-Z_.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  defaultValue=""
                  value={opportunityApplicationLink}
                  setFunction={setOpportunityApplicationLink}
                  errorMessage="This application link is invalid, please enter a different application link like https://yoursite.com or youremail@email.com."
                  required={true}
                  multiline={false}
                  select={false}
                  maxLength={100}
                  error={opportunityApplicationLinkError}
                  setError={setOpportunityApplicationLinkError}
                  children={{}}
                />
              </Grid>
              <Grid item>
                <DialogContentText>Description *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    minHeight: 130,
                    overflowY: "auto",
                    "& .MuiInputBase-input": {
                      minHeight: 180,
                    },
                  }}
                  placeholder="This is an opportunity for a Chemistry internship."
                  label="Description"
                  regexPattern="(?=.*\w).+"
                  value={opportunityDescription}
                  setFunction={setOpportunityDescription}
                  errorMessage="This description is invalid, please enter a different description."
                  required={true}
                  multiline={true}
                  select={false}
                  maxLength={10000}
                  error={opportunityDescriptionError}
                  setError={setOpportunityDescriptionError}
                  children={{}}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid item>
                <DialogContentText>Citizenship Type *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 55,
                    },
                  }}
                  placeholder=""
                  label="Citizenship Type"
                  regexPattern="(?=.*\w).+"
                  defaultValue={citizenshipTypes[0].id}
                  value={opportunityCitizenshipType}
                  setFunction={setOpportunityCitizenshipType}
                  errorMessage="This citizenship type is invalid, please enter a different citizenship type."
                  required={true}
                  multiline={false}
                  select={true}
                  maxLength={100}
                  error={opportunityCitizenshipTypeError}
                  setError={setOpportunityCitizenshipTypeError}
                  children={citizenshipTypes.map((citizenshipType) => (
                    <MenuItem
                      key={citizenshipType.id}
                      value={citizenshipType.id}
                    >
                      {citizenshipType.label}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item>
                <DialogContentText>Job Type *</DialogContentText>
                <EnhancedTextField
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 55,
                    },
                  }}
                  placeholder=""
                  label="Job Type"
                  regexPattern="(?=.*\w).+"
                  defaultValue={jobTypes[0].id}
                  value={opportunityJobType}
                  setFunction={setOpportunityJobType}
                  errorMessage="This job type is invalid, please enter a different job type."
                  required={true}
                  multiline={false}
                  select={true}
                  maxLength={100}
                  error={opportunityJobTypeError}
                  setError={setOpportunityJobTypeError}
                  children={jobTypes.map((jobType) => (
                    <MenuItem key={jobType.id} value={jobType.id}>
                      {jobType.label}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item>
                <DialogContentText>Apply Deadline *</DialogContentText>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    defaultValue={opportunityApplicationDeadline}
                    label="Apply Deadline"
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 55,
                      },
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                        margin: "normal",
                        size: "normal",
                        required: true,
                      },
                    }}
                    onChange={(newDate) =>
                      setOpportunityApplicationDeadline(newDate)
                    }
                    disablePast
                    shouldDisableDate={isWeekend}
                  ></DatePicker>
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <DialogContentText>Location *</DialogContentText>
                <LocationAutocomplete
                  opportunityLocation={opportunityLocation}
                  setOpportunityLocation={setOpportunityLocation}
                  opportunityLocationError={opportunityLocationError}
                  setOpportunityLocationError={setOpportunityLocationError}
                  opportunityCity={opportunityCity}
                  setOpportunityCity={setOpportunityCity}
                  opportunityState={opportunityState}
                  setOpportunityState={setOpportunityState}
                />
              </Grid>

              <Grid item>
                <DialogContentText>Skills</DialogContentText>
                <Autocomplete
                  multiple
                  options={skills}
                  value={selectedOpportunitySkills}
                  getOptionLabel={(option) => option.skillName}
                  isOptionEqualToValue={(option, value) =>
                    option.skillName === value.skillName
                  }
                  filterSelectedOptions
                  onChange={(event, value) => {
                    setSelectedOpportunitySkills(value);
                  }}
                  onBlur={handleSkillsBlur}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Skills"
                      placeholder="Skills"
                      onChange={(event) =>
                        handleSkillSearch(event.target.value)
                      }
                      sx={{
                        "& .MuiInputBase-root": {
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          minHeight: "55px",
                          "& .MuiButtonBase-root": {},
                          "& .MuiInputBase-input": {
                            width: "100%",
                            order: -1,
                          },
                        },
                      }}
                      error={selectedOpportunitySkillsError}
                      helperText={
                        selectedOpportunitySkillsError
                          ? "Please select at least one skill."
                          : ""
                      }
                    />
                  )}
                  sx={{
                    width: "100%",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSubmit()}>
            Submit Edited Opportunity
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

EditOpportunity.props = {
  opportunityID: PropTypes.number.isRequired,
  jobTypes: PropTypes.array.isRequired,
  citizenshipTypes: PropTypes.array.isRequired,
  updateTable: PropTypes.bool.isRequired,
  setUpdateTable: PropTypes.func.isRequired,
};

export default EditOpportunity;
