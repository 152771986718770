import * as React from "react";

import PropTypes from "prop-types";

import { TextField } from "@mui/material";
import { AddressAutofill } from "@mapbox/search-js-react";

import EnhancedTextField from "./EnhancedTextField";

const LocationAutocomplete = ({
  opportunityLocation,
  setOpportunityLocation,
  opportunityLocationError,
  setOpportunityLocationError,
  opportunityCity,
  setOpportunityCity,
  opportunityState,
  setOpportunityState,
}) => {  
  return (
    <form>
      <AddressAutofill
        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        options={{
          language: "en",
          country: "US",
          proximity: "ip",
        }}
      >
        <EnhancedTextField
          sx={{
            "& .MuiInputBase-root": {
              height: 55,
            },
          }}
          placeholder="Please type in a location..."
          label="Location"
          regexPattern="(?=.*\w).+"
          defaultValue=""
          value={opportunityLocation}
          setFunction={setOpportunityLocation}
          errorMessage="This location is invalid, please enter a different location."
          required={true}
          multiline={false}
          select={false}
          maxLength={1000}
          error={opportunityLocationError}
          setError={setOpportunityLocationError}
          children={{}}
          inputProps={{
            autoComplete: "address-line1",
          }}
        />
        <TextField
          placeholder="City"
          margin="dense"
          size="small"
          fullWidth
          value={opportunityCity}
          inputProps={{
            autoComplete: "address-level2",
            readOnly: true,
          }}
          onChange={(e) => setOpportunityCity(e.target.value)}
        />
        <TextField
          placeholder="State"
          margin="dense"
          size="small"
          fullWidth
          value={opportunityState}
          inputProps={{
            autoComplete: "address-level1",
            readOnly: true,
          }}
          onChange={(e) => setOpportunityState(e.target.value)}
        />
      </AddressAutofill>
    </form>
  );
};

LocationAutocomplete.propTypes = {
  opportunityLocation: PropTypes.string,
  setOpportunityLocation: PropTypes.func,
  opportunityLocationError: PropTypes.string,
  setOpportunityLocationError: PropTypes.func,
  opportunityCity: PropTypes.string,
  setOpportunityCity: PropTypes.func,
  opportunityState: PropTypes.string,
  setOpportunityState: PropTypes.func,
};

LocationAutocomplete.defaultProps = {
  opportunityLocation: "",
  setOpportunityLocation: () => {},
  opportunityLocationError: "",
  setOpportunityLocationError: () => {},
  opportunityCity: "",
  setOpportunityCity: () => {},
  opportunityState: "",
  setOpportunityState: () => {},
};

export default LocationAutocomplete;
