import React, { useState, useEffect } from "react";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Chip, Grid, Radio, Checkbox, TextField, RadioGroup, Typography, Autocomplete, FormControlLabel } from "@mui/material";

import SkillsService from "../api/services/SkillsService";
import AddOpportunity from "../components/AddOpportunity";
import EditOpportunity from "../components/EditOpportunity";
import DeleteOpportunity from "../components/DeleteOpportunity";
import CurrentOpportunity from "../components/CurrentOpportunity";
import OpportunitiesService from "../api/services/OpportunitiesService";
import CitizenshipTypeHelper from "../components/CitizenshipTypeHelper";

const Main = ({ userAffiliation }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [currentSearchTitle, setCurrentSearchTitle] = useState("");
  const [citizenshipType, setCitizenshipType] = useState("");
  const [currentOpportunity, setCurrentOpportunity] = useState(null);
  const [jobType, setJobType] = useState({
    internship: false,
    undergraduateResearch: false,
  });
  const [timeFilter, setTimeFilter] = useState({
    justAdded: false,
    expired: false,
  });

  const { internship, undergraduateResearch } = jobType;
  const { justAdded, expired } = timeFilter;

  const handleJobTypeChange = (event) => {
    setJobType({
      ...jobType,
      [event.target.name]: event.target.checked,
    });
  };

  const handleTimeFilterChange = (event) => {
    setTimeFilter({
      ...timeFilter,
      [event.target.name]: event.target.checked,
    });
  };

  const [updateTable, setUpdateTableHook] = useState(false);
  const setUpdateTable = (value) => {
    setUpdateTableHook(value);
  };

  const jobTypes = [
    {
      id: "internship",
      label: "Internship",
    },
    {
      id: "undergraduateResearch",
      label: "Undergraduate Research",
    },
  ];

  const citizenshipTypes = [
    {
      id: "usCitizen",
      label: "US Citizen",
    },
    {
      id: "permanentResident",
      label: "Permanent Resident",
    },
    {
      id: "nonCitizen",
      label: "Non-Citizen",
    },
  ];

  const timeFilters = [
    {
      id: "justAdded",
      label: "Just Added",
    },
    {
      id: "expired",
      label: "Expired",
    },
  ];

  useEffect(() => {
    async function fetchData() {
      await OpportunitiesService.getOpportunities().then((response) => {
        const data = response.data;
        setOpportunities(data);
      });

      await SkillsService.getSkills().then((response) => {
        const data = response.data;
        setSkills(data);
      });
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        await OpportunitiesService.getOpportunitiesBySearchTerms(
          currentSearchTitle,
          internship,
          undergraduateResearch,
          citizenshipType,
          justAdded,
          expired,
          selectedSkills
        ).then((response) => {
          const data = response.data;
          setOpportunities(data);
        });
      } catch (error) {
        setOpportunities([]);
      }
    }
    fetchData();
  }, [
    currentSearchTitle,
    internship,
    undergraduateResearch,
    citizenshipType,
    justAdded,
    expired,
    selectedSkills,
    updateTable,
  ]);

  const handleSkillSearch = (skillNameQuery) => {
    async function fetchData() {
      try {
        await SkillsService.getSkillByNameQuery(skillNameQuery).then(
          (response) => {
            const data = response.data;
            setSkills(data);
          }
        );
      } catch (error) {
        setSkills([]);
      }
    }
    if (skillNameQuery) {
      fetchData();
    }
  };

  const renderOpportunities = () => {
    return opportunities.map((opportunity) => {
      return (
        <Grid
          container
          key={opportunity.opportunityID}
          onClick={() => setCurrentOpportunity(opportunity)}
          sx={{ flexDirection: "column", mb: 2 }}
          className="Home-opportunityContainer"
        >
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Typography
              variant="body1"
              sx={{
                color: "primary.main",
                fontWeight: 500,
                fontSize: "24px",
                overflow: "hidden",
                width: "80%",
              }}
              className="Home-opportunityTitle"
            >
              {opportunity.opportunityTitle}
            </Typography>
            <Grid sx={{ display: "flex" }}>
              {userAffiliation === "employee" && (
                <EditOpportunity
                  opportunityID={opportunity.opportunityID}
                  jobTypes={jobTypes}
                  citizenshipTypes={citizenshipTypes}
                  updateTable={updateTable}
                  setUpdateTable={setUpdateTable}
                />
              )}
              {userAffiliation === "employee" && (
                <DeleteOpportunity
                  opportunityID={opportunity.opportunityID}
                  updateTable={updateTable}
                  setUpdateTable={setUpdateTable}
                />
              )}
            </Grid>
          </Grid>
          <Typography
            variant="body1"
            sx={{
              color: "secondary.main",
              fontWeight: 500,
              fontSize: "20px",
              flexGrow: 1,
            }}
            className="Home-opportunityCompany"
          >
            {opportunity.opportunityCompany}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "tertiary.main",
              fontWeight: 500,
              fontSize: "16px",
              flexGrow: 1,
              mb: 2,
            }}
            className="Home-opportunityLocation"
          >
            {opportunity.opportunityLocation !== "None, None"
              ? opportunity.opportunityLocation
              : "N/A"}
          </Typography>
          <Grid
            container
            sx={{ mb: 0.5, gap: 0.25 }}
            className="Home-opportunityCitizenshipAndJobType"
          >
            <Chip
              label={
                citizenshipTypes.find(
                  (citizenshipType) =>
                    citizenshipType.id === opportunity.opportunityCitizenship
                ).label
              }
              size="small"
              color="primary"
              className="Home-opportunityCitizenship"
            />
            <Chip
              label={
                jobTypes.find(
                  (jobType) => jobType.id === opportunity.opportunityType
                ).label
              }
              size="small"
              color="primary"
              className="Home-opportunityType"
            />
          </Grid>
          <Grid container sx={{ gap: 0.25 }} className="Home-opportunitySkills">
            {opportunity.opportunitySkills &&
              opportunity.opportunitySkills
                .split(";")
                .slice(0, 3)
                .map((skill) => (
                  <Chip
                    key={Math.random() * 10000}
                    label={skill}
                    size="small"
                    color="secondary"
                    className="Home-opportunitySkills"
                  />
                ))}
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <Grid
      container
      sx={{
        height: "calc(100vh - 128px)",
        backgroundColor: "background.main",
        p: 2,
      }}
      className="Home"
    >
      <Grid item xs={12} sx={{ display: "flex", mb: 2 }} className="Home-top">
        <TextField
          variant="outlined"
          label="Search for your next opportunity!"
          size="small"
          color="secondary"
          sx={{
            "& .MuiInputLabel-root": { color: "#861F41" },
            "& .MuiOutlinedInput-root": {
              "& > fieldset": {
                borderColor: "secondary.main",
              },
              borderRadius: 2,
              backgroundColor: "white",
            },
            "& .MuiInputBase-root": {
              height: 40,
            },
            flexGrow: 1,
          }}
          onChange={(event) => setCurrentSearchTitle(event.target.value)}
          className="Home-JobTitlesSearch"
        />
        {userAffiliation === "employee" && (
          <AddOpportunity
            jobTypes={jobTypes}
            citizenshipTypes={citizenshipTypes}
            updateTable={updateTable}
            setUpdateTable={setUpdateTable}
          />
        )}
      </Grid>

      <Grid
        container
        sx={{
          backgroundColor: "white",
          border: "2px solid",
          borderColor: "secondary.main",
          borderRadius: 5,
          height: "calc(100vh - 234px)",
        }}
        className="Home-bottom"
      >
        <Grid
          item
          sx={{
            width: "20%",
            p: 2,
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
            display: "flex",
          }}
          className="Home-left"
        >
          <Typography
            variant="body1"
            sx={{
              mx: "auto",
              color: "primary.main",
              fontWeight: 500,
              fontSize: "24px",
            }}
            className="Home-filtersTitle"
          >
            Filters
          </Typography>

          <Grid
            container
            sx={{ flexDirection: "column", mb: 2 }}
            className="Home-timeFiltersContainer"
          >
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontStyle: "italic",
                fontSize: "20px",
              }}
              className="Home-citizenshipTypesTitle"
            >
              Time Based
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={justAdded}
                  onChange={(event) => handleTimeFilterChange(event)}
                  name={timeFilters[0].id}
                  color="tertiary"
                />
              }
              label={
                <Typography
                  variant="body1"
                  sx={{
                    color: "tertiary.main",
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "18px",
                  }}
                  className="Home-jobType"
                >
                  {timeFilters[0].label}
                </Typography>
              }
              name="justAdded"
              labelPlacement="start"
              sx={{
                justifyContent: "space-between",
              }}
            />
            {userAffiliation === "employee" && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={expired}
                    onChange={(event) => handleTimeFilterChange(event)}
                    name={timeFilters[1].id}
                    color="tertiary"
                  />
                }
                label={
                  <Typography
                    variant="body1"
                    sx={{
                      color: "tertiary.main",
                      fontWeight: 500,
                      fontStyle: "italic",
                      fontSize: "18px",
                    }}
                    className="Home-jobType"
                  >
                    {timeFilters[1].label}
                  </Typography>
                }
                labelPlacement="start"
                sx={{ justifyContent: "space-between" }}
              />
            )}
          </Grid>
          <Grid
            container
            sx={{ flexDirection: "column", mb: 2 }}
            className="Home-jobTypesContainer"
          >
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontStyle: "italic",
                fontSize: "20px",
              }}
              className="Home-jobTypesTitle"
            >
              Job Types
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={internship}
                  onChange={(event) => handleJobTypeChange(event)}
                  name={jobTypes[0].id}
                  color="tertiary"
                />
              }
              label={
                <Typography
                  variant="body1"
                  sx={{
                    color: "tertiary.main",
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "18px",
                  }}
                  className="Home-jobType"
                >
                  {jobTypes[0].label}
                </Typography>
              }
              name="internship"
              labelPlacement="start"
              sx={{
                justifyContent: "space-between",
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={undergraduateResearch}
                  onChange={(event) => handleJobTypeChange(event)}
                  name={jobTypes[1].id}
                  color="tertiary"
                />
              }
              label={
                <Typography
                  variant="body1"
                  sx={{
                    color: "tertiary.main",
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "18px",
                  }}
                  className="Home-jobType"
                >
                  {jobTypes[1].label}
                </Typography>
              }
              labelPlacement="start"
              sx={{ justifyContent: "space-between" }}
            />
          </Grid>
          <Grid
            container
            sx={{ flexDirection: "column", mb: 2 }}
            className="Home-Home-citizenshipTypesContainer"
          >
            <Grid item sx={{ display: "flex" }}>
              <Typography
                variant="body1"
                sx={{
                  color: "secondary.main",
                  fontWeight: 500,
                  fontStyle: "italic",
                  fontSize: "20px",
                  my: "auto",
                  mr: 1
                }}
                className="Home-citizenshipTypesTitle"
              >
                Citizenship
              </Typography>
              <CitizenshipTypeHelper />
            </Grid>
            <RadioGroup
              value={citizenshipType}
              onChange={(event) => setCitizenshipType(event.target.value)}
            >
              {citizenshipTypes.map((citizenType) => {
                return (
                  <FormControlLabel
                    key={citizenType.id}
                    value={citizenType.id}
                    control={
                      <Radio
                        checkedIcon={<CheckBoxIcon />}
                        icon={<CheckBoxOutlineBlankIcon />}
                        color="tertiary"
                      />
                    }
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          color: "tertiary.main",
                          fontWeight: 500,
                          fontStyle: "italic",
                          fontSize: "18px",
                        }}
                        className="Home-jobType"
                      >
                        {citizenType.label}
                      </Typography>
                    }
                    labelPlacement="start"
                    sx={{ justifyContent: "space-between" }}
                  />
                );
              })}
            </RadioGroup>
          </Grid>
          <Grid
            container
            sx={{ flexDirection: "column" }}
            className="Home-skillsContainer"
          >
            <Typography
              variant="body1"
              sx={{
                color: "secondary.main",
                fontWeight: 500,
                fontStyle: "italic",
                fontSize: "20px",
              }}
              className="Home-skillsTitle"
            >
              Skills
            </Typography>
            <Autocomplete
              multiple
              options={skills}
              getOptionLabel={(option) => option.skillName}
              isOptionEqualToValue={(option, value) =>
                option.skillName === value.skillName
              }
              filterSelectedOptions
              onChange={(event, value) => {
                setSelectedSkills(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Skills"
                  placeholder="Skills"
                  onChange={(event) => handleSkillSearch(event.target.value)}
                  sx={{
                    "& .MuiInputBase-root": {
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      minHeight: "55px",
                      "& .MuiButtonBase-root": {},
                      "& .MuiInputBase-input": {
                        width: "100%",
                        order: -1,
                      },
                    },
                  }}
                />
              )}
              sx={{
                width: "100%",
              }}
            />
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            width: "40%",
            height: "100%",
            p: 2,
            flexDirection: "column",
            overflowY: "auto",
          }}
          className="Home-middle"
        >
          {opportunities.length > 0 ? (
            renderOpportunities()
          ) : (
            <Typography variant="h4" sx={{ fontWeight: 500 }}>
              There are no opportunities available, please use different search
              terms.
            </Typography>
          )}
        </Grid>

        <Grid
          item
          sx={{
            width: "40%",
            height: "100%",
            p: 2,
            overflowY: "auto",
          }}
          className="Home-right"
        >
          <CurrentOpportunity
            currentOpportunity={currentOpportunity}
            jobTypes={jobTypes}
            citizenshipTypes={citizenshipTypes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Main;
