import React from "react";

import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Grid,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const CitizenshipTypeHelper = () => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <IconButton onClick={handleOpen} sx={{ p: 0 }}>
        <InfoIcon fontSize="large" color="quaternary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <Grid container>
          <DialogTitle
            color="primary"
            sx={{ fontSize: 24, fontWeight: 600, mx: "auto" }}
          >
            Choosing the Right Citizenship Type
          </DialogTitle>
          <IconButton onClick={handleClose} sx={{ pr: 2 }}>
            <ClearIcon fontSize="large" color="primary" />
          </IconButton>
        </Grid>
        <DialogContent>
          <Grid item>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 600 }}>
              US Citizen
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 400, ml: 2 }}>
              Only US Citizens can apply to these positions.
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 600 }}>
              Permanent Resident
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 400, ml: 2 }}>
              Both US Citizens and Permanent Resident can apply to
              these positions.
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 600 }}>
              Non-Citizen
            </DialogContentText>
            <DialogContentText sx={{ fontSize: 20, fontWeight: 400, ml: 2 }}>
              Anyone can apply to these positions.
            </DialogContentText>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default CitizenshipTypeHelper;
