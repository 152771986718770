import * as React from "react";

import PropTypes from "prop-types";

import ClearIcon from "@mui/icons-material/Clear";
import { Grid, Dialog, Button, IconButton, DialogTitle, DialogActions } from "@mui/material";

import OpportunitiesService from "../api/services/OpportunitiesService";

const DeleteOpportunity = ({ opportunityID, updateTable, setUpdateTable }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    OpportunitiesService.removeOpportunityById(opportunityID);
    setUpdateTable(!updateTable);
    setOpen(false);
  };

  return (
    <Grid item xs={1}>
      <IconButton onClick={handleOpen} sx={{ p: 0 }}>
        <ClearIcon fontSize="large" color="primary" />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle
          color="primary"
          sx={{ fontSize: 24, fontWeight: 600, mx: "auto" }}
        >
          Are you sure you want to delete this opportunity?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDelete}>Delete Opportunity</Button>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

DeleteOpportunity.props = {
  currentOpportunity: PropTypes.number.isRequired,
  updateTable: PropTypes.bool.isRequired,
  setUpdateTable: PropTypes.func.isRequired,
};

export default DeleteOpportunity;
