import React from "react";

import PropTypes from "prop-types";

import { TextField } from "@mui/material";

const EnhancedTextField = ({
  sx,
  placeholder,
  label,
  value,
  defaultValue,
  setFunction,
  regexPattern,
  errorMessage,
  required,
  multiline,
  select,
  maxLength,
  error,
  setError,
  children,
  inputProps
}) => {
  const regex = new RegExp(regexPattern);

  const handleChange = (event) => {
    if (event.target.value.length > maxLength) {
      setError(true);
      return;
    }
    setFunction(event.target.value);
    setError(false); // reset error when user changes input
  };

  const handleBlur = () => {
    if (value.trim() === "") {
      if (!required) {
        setError(false);
        return;
      }
      setError(true);
      return;
    }
    if (!regex) {
      setError(false);
      return;
    }
    if (regex.test(value) && value.length === value.match(regex)[0].length) {
      setError(false);
      return;
    }
    setError(true);
  };

  return (
    <TextField
      className="EnhancedTextField"
      margin="normal"
      size="normal"
      type="text"
      fullWidth
      sx={sx}
      variant="outlined"
      placeholder={placeholder}
      label={label}
      required={required}
      multiline={multiline}
      select={select}
      value={value}
      defaultValue={defaultValue}
      onChange={handleChange}
      onBlur={handleBlur}
      error={error}
      helperText={error ? errorMessage : ""}
      inputProps={inputProps ? inputProps : { maxLength: maxLength }}
    >{children}</TextField>
  );
};

EnhancedTextField.propTypes = {
  sx: PropTypes.object,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  setFunction: PropTypes.func,
  regexPattern: PropTypes.string,
  errorMessage: PropTypes.string,
  required: PropTypes.bool,
  multiline: PropTypes.bool,
  select: PropTypes.bool,
  maxLength: PropTypes.number,
  error: PropTypes.bool,
  setError: PropTypes.func,
  children: PropTypes.array,
  inputProps: PropTypes.object
};

EnhancedTextField.defaultProps = {
  sx: {},
  placeholder: "",
  label: "",
  value: "",
  defaultValue: "",
  setFunction: () => {},
  regexPattern: "",
  errorMessage: "",
  required: false,
  multiline: false,
  select: false,
  maxLength: 10000,
  error: false,
  setError: () => {},
  children: [],
  inputProps: { maxLength: 10000 },
};

export default EnhancedTextField;
