import React from "react";

import { Grid, Button, Typography } from "@mui/material";

const Login = () => {
  return (
    <Grid
      container
      sx={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: "1",
        height: "calc(100vh - 128px)",
        backgroundColor: "background.main",
      }}
      className="Login"
    >
      <Typography
        variant="h2"
        fontWeight="600"
        component="div"
        sx={{
          mb: 8,
        }}
      >
        Please Login to Begin!
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{
          fontSize: 40,
          py: 4,
          px: 8,
          borderRadius: 5,
        }}
        href="/api/auth/login"
        className="Login-button"
      >
        CAS Login
      </Button>
    </Grid>
  );
};

export default Login;
