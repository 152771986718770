import baseAPI from "../api";

const create = (data) => {
  return baseAPI.post("/users", data);
};

const getUsers = () => {
  return baseAPI.get("/users");
};

const getCurrentUser = () => {
  return baseAPI.get("/users/login/getCurrentUser");
};

const getUserByName = (userName) => {
  return baseAPI.get(`/users/${userName}`);
};

const updateUserByName = (userName, data) => {
  return baseAPI.put(`/users/${userName}`, data);
};

const removeUserByName = (userName) => {
  return baseAPI.delete(`/users/${userName}`);
};

const removeUsers = () => {
  return baseAPI.delete("/users");
};

const UsersService = {
  create,
  getUsers,
  getCurrentUser,
  getUserByName,
  updateUserByName,
  removeUserByName,
  removeUsers,
};

export default UsersService;
