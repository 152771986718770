import * as React from "react";

import { Grid, Link, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Grid
      container
      component="footer"
      sx={{ p: 2, justifyContent: "center", backgroundColor: "primary.main" }}
      className="Footer"
    >
      <Typography variant="h6" sx={{ color: "white" }}>
        {new Date().getFullYear() + " "}
        <Link
          href="https://www.science.vt.edu/"
          sx={{ color: "inherit", textDecoration: "none" }}
        >
          Virginia Tech College Of Science
        </Link>
        {". All Rights Reserved."}
      </Typography>
    </Grid>
  );
};

export default Footer;
