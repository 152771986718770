import baseAPI from "../api";

const create = (data) => {
  return baseAPI.post("/opportunities", data);
};

const getOpportunities = () => {
  return baseAPI.get("/opportunities");
};

const getOpportunitiesBySearchTerms = (
  currentSearchTitle,
  internship,
  undergraduateResearch,
  citizenshipType,
  justAdded, expired,
  selectedSkills
) => {
  let searchTerms = [];

  if (currentSearchTitle) {
    searchTerms.push("opportunityTitle=" + currentSearchTitle);
  }

  if (internship) {
    searchTerms.push("opportunityTypeI=internship");
  }
  if (undergraduateResearch) {
    searchTerms.push("opportunityTypeUGR=undergraduateResearch");
  }

  if (citizenshipType) {
    searchTerms.push("opportunityCitizenship=" + citizenshipType);
  }

  if (justAdded) {
    searchTerms.push("opportunityJustAdded=" + justAdded);
  }

  if (expired) {
    searchTerms.push("opportunityExpired=" + expired);
  }

  if (selectedSkills.length > 0) {
    console.log(selectedSkills);
    let selSkillsString = "";
    for (
      let selSkillIndex = 0;
      selSkillIndex < selectedSkills.length;
      selSkillIndex++
    ) {
      if (selSkillIndex < selectedSkills.length - 1) {
        selSkillsString += selectedSkills[selSkillIndex].skillName + ";";
      } else {
        selSkillsString += selectedSkills[selSkillIndex].skillName;
      }
    }
    searchTerms.push("opportunitySkills=" + selSkillsString);
  }

  let searchTermsString = "";
  for (
    let searchTermsIndex = 0;
    searchTermsIndex < searchTerms.length;
    searchTermsIndex++
  ) {
    if (searchTermsIndex < searchTerms.length - 1) {
      searchTermsString += searchTerms[searchTermsIndex] + "&";
    } else {
      searchTermsString += searchTerms[searchTermsIndex];
    }
  }

  if (searchTermsString) {
    console.log("There are search terms." + searchTermsString);
    return baseAPI.get(`/opportunities/search/terms?${searchTermsString}`);
  }
  console.log("There are no search terms.");
  return baseAPI.get(`/opportunities/search/terms`);
};

const getOpportunityById = (opportunityId) => {
  return baseAPI.get(`/opportunities/${opportunityId}`);
};

const updateOpportunityById = (opportunityId, data) => {
  return baseAPI.put(`/opportunities/${opportunityId}`, data);
};

const removeOpportunityById = (opportunityId) => {
  return baseAPI.delete(`/opportunities/${opportunityId}`);
};

const removeOpportunities = () => {
  return baseAPI.delete("/opportunities");
};

const OpportunitiesService = {
  create,
  getOpportunities,
  getOpportunitiesBySearchTerms,
  getOpportunityById,
  updateOpportunityById,
  removeOpportunityById,
  removeOpportunities,
};

export default OpportunitiesService;
