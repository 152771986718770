import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";

import App from "./App";

import "./styles/index.css";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Rubik",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
  },
  palette: {
    // VT Maroon
    primary: {
      main: "#861F41",
      light: "#861F41",
      dark: "#861F41",
    },
    // VT Orange
    secondary: {
      main: "#E5751F",
      light: "#E5751F",
      dark: "#E5751F",
    },
    // VT Gray
    tertiary: {
      main: "#75787B",
      light: "#75787B",
      dark: "#75787B",
    },
    background: {
      main: "#75787B",
      light: "#75787B",
      dark: "#75787B",
    },
    // VT Turquiose
    quaternary: {
      main: "#2CD5C4",
      light: "#2CD5C4",
      dark: "#2CD5C4",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);
